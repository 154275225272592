import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Caption>`}</inlineCode>{` component is typically used below images or videos. They will
default to 4 columns wide unless placed inside a `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` component. In this
case, it will assume the full width of its containing column.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Normal</Title>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABxElEQVQoz33PYWvTQBgH8HutQ/TbOPwC86O0s2mTdOCLvRCEMRmDgXsj7l1hc6ATZG7dcB12rTZrK3Xt0tw9l7tL0gyN1HQwaE1oK4tF0iE+/Dnujvvdw4PG0zUajcbj8WAwoLECAIwxAESHKFGhvyC+8X1/8jSSQgjP8zjjQMDk0SW5/gIFQRDv3O/3HccxDCMuSyclSZIqtc/CEXqVAqbMBsoAMca63e5wOAzD0PM8AGCMdTqdP5gQ4rru9uvtu3fuba2+ab68KGRFccmpv7WMrwwRQrFBBLcYE4ZBOBOnWnXv/b5pmpMJOcV5XMxWtDQuPYKPGXaUsvPJi8PH3xC3gNtgCswsQrnhX/5IK/O3Z27pun49JAWqQ3PxSyN1VlNbmtouy1CU2bFiH0ouyq/38uu9g2jdf+4fvbjaWDhfnt+dtLVN2G21pcqZWmvIjZrc0uTIZ1ghY6NcIpxOsJkYHawEACbGmFAwVyskVW6rWlOp3/BoKxPE80oOcslfO097FGjnu+vUwVILTC0S5VNbuenRphRMJR3kEuG7pSsK9P6D2ScLi5fPqlz68E//P/xwbm55beXnTstJ7lnZ48iX4/43fWb+nDQt+l0AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Colors",
        "title": "Colors",
        "src": "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png",
        "srcSet": ["/static/5737718955f2cba55b78b5e66b0cc215/7fc1e/design-for-ai-art.png 288w", "/static/5737718955f2cba55b78b5e66b0cc215/a5df1/design-for-ai-art.png 576w", "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png 1152w", "/static/5737718955f2cba55b78b5e66b0cc215/0b124/design-for-ai-art.png 1728w", "/static/5737718955f2cba55b78b5e66b0cc215/20ee3/design-for-ai-art.png 1896w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <Title mdxType="Title">Full-width</Title>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsSAAALEgHS3X78AAABxElEQVQoz33PYWvTQBgH8HutQ/TbOPwC86O0s2mTdOCLvRCEMRmDgXsj7l1hc6ATZG7dcB12rTZrK3Xt0tw9l7tL0gyN1HQwaE1oK4tF0iE+/Dnujvvdw4PG0zUajcbj8WAwoLECAIwxAESHKFGhvyC+8X1/8jSSQgjP8zjjQMDk0SW5/gIFQRDv3O/3HccxDCMuSyclSZIqtc/CEXqVAqbMBsoAMca63e5wOAzD0PM8AGCMdTqdP5gQ4rru9uvtu3fuba2+ab68KGRFccmpv7WMrwwRQrFBBLcYE4ZBOBOnWnXv/b5pmpMJOcV5XMxWtDQuPYKPGXaUsvPJi8PH3xC3gNtgCswsQrnhX/5IK/O3Z27pun49JAWqQ3PxSyN1VlNbmtouy1CU2bFiH0ouyq/38uu9g2jdf+4fvbjaWDhfnt+dtLVN2G21pcqZWmvIjZrc0uTIZ1ghY6NcIpxOsJkYHawEACbGmFAwVyskVW6rWlOp3/BoKxPE80oOcslfO097FGjnu+vUwVILTC0S5VNbuenRphRMJR3kEuG7pSsK9P6D2ScLi5fPqlz68E//P/xwbm55beXnTstJ7lnZ48iX4/43fWb+nDQt+l0AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Colors",
        "title": "Colors",
        "src": "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png",
        "srcSet": ["/static/5737718955f2cba55b78b5e66b0cc215/7fc1e/design-for-ai-art.png 288w", "/static/5737718955f2cba55b78b5e66b0cc215/a5df1/design-for-ai-art.png 576w", "/static/5737718955f2cba55b78b5e66b0cc215/3cbba/design-for-ai-art.png 1152w", "/static/5737718955f2cba55b78b5e66b0cc215/0b124/design-for-ai-art.png 1728w", "/static/5737718955f2cba55b78b5e66b0cc215/20ee3/design-for-ai-art.png 1896w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <Caption fullWidth mdxType="Caption">
  This is a full width caption. With this prop, the caption’s width will be 100%
  so it will fill its container.
    </Caption>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Normal</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption>
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
</Caption>
`}</code></pre>
    <Title mdxType="Title">Full</Title>-width
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Caption/Caption.js src= https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption",
        "path": "components/Caption/Caption.js",
        "src": "",
        "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Caption": true
      }}>{`<Caption fullWidth>


This is a full width caption. With this prop, the caption’s width will be 100%
so it will fill its container.

</Caption>

`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fullWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to full width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      